video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
}

.homepage-container {
  overflow: hidden;
}

.title-text {
  font-size: 45px;
  font-family: "inter", sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 40px;
  margin-bottom: 140px;
  color: white;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.darken-video {
  background-color: rgba(12, 12, 12, 0.401);
  z-index: -1;
  height: 100%;
  width: 100%;
  position: fixed;
}

.mobile-buttons {
  position: fixed;
  bottom: 0;
  padding-left: 64px;
  padding-bottom: 20px;
}

.location {
}

.ring-container {
  position: relative;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #1bbe8d;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: -23px;
}

h {
  color: white;
  font-family: "inter", sans-serif;
  font-size: 0.8rem;
  font-weight: 100;
}

.ringring {
  border: 3px solid #1bbe8d;
  -webkit-border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -31px;
  top: -2px;
  -webkit-animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

.indented-line {
  padding-left: 20px;
}

@media (max-width: 960px) {
  .title-text {
    font-size: 40px;
    width: 80%;
  }
}

@media (max-width: 720px) {
  .title-text {
    font-size: 35px;
    width: 80%;
  }
}

@media (max-width: 560px) {
  .title-text {
    font-size: 30px;
    width: 80%;
  }
}
