.portfolio {
  font-family: "inter", sans-serif;
  overflow: hidden;
}

.back-button-container-port {
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
}

.back-button-port {
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  transition: transform 0.15s ease-in-out;
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1.4rem;
  padding-top: 30px;
  padding-right: 35px;
  cursor: pointer;
  z-index: 1;
}

.back-button-port:hover {
  transform: scale(1.05);
}

.back-text-port {
  padding-top: 10px;
  font-family: "inter", sans-serif;
  text-decoration: none;
}

.back-text {
  padding-top: 10px;
  font-family: "inter", sans-serif;
  text-decoration: none;
}

.back-icon-port {
  padding-left: 15px;
  padding-top: 10px;
}

.portfolio-tag {
  color: #afbfd6;
  line-height: "4";
  margin-bottom: 80px;
}

.portfolio-tag-mobile {
  color: #afbfd6;
  padding-top: 43px;
  padding-left: 50px;
}

.portfolio-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.projects-container {
  flex: 2;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.projects-container::-webkit-scrollbar {
  display: none; /* hide scroll bar (chrome, safari) */
}

.projects {
  overflow: hidden;
}

.title {
  text-align: center;
  color: white;
  font-size: 40px;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.cards-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 28px;
  box-sizing: border-box;
}

h4 {
  font-size: 20px;
}

.video-stats {
  display: flex;
  justify-content: space-between;
}

.profile-container {
  flex: 1;
  background: white;
  position: sticky;
  height: 100%;
}

.profile-container-mobile {
  background: white;
  height: 100vh;
  position: relative;
}

.profile {
  display: flex;
  justify-content: center; 
  height: 100vh; 
  flex-direction: column;
  margin-left: 30px;
  padding: 20px;
}

.profile-mobile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  height: 500px;
}

.headshot-square {
  height: 180px;
  width: 180px;
  border-radius: 10px;
}

.github-name {
  color: black;
  font-size: x-large;
  padding-top: 25px;
}

.github-link {
  padding-top: 5px;
  font-size: larger;
  color: black;
  text-decoration: none;
}

.website-link {
  text-decoration: none;
  color: black;
  margin-top: 45px;
  display: flex;
}

.link-name {
  padding-left: 5px;
  padding-top: 1.5px;
}

.current-place {
  display: flex;
  padding-top: 5px;
}

.place-name {
  padding-top: 2px;
  padding-left: 5px;
}

.current-work {
  display: flex;
  padding-top: 5px;
}

.work-name {
  padding-top: 3px;
  padding-left: 5px;
}

.icons-section {
  padding-top: 20px;
}

.social-icon-link-black {
  font-size: 25px;
  padding-bottom: 40px;
  z-index: 999;
  padding-right: 30px;
  color: black;
  transition: transform 0.3s ease-in-out;
}

.social-icon-link-black:hover {
  transform: scale(1.1);
}

.arrow-text {
  margin-top: 2px;
  padding-left: 5px;
}

.scroll {
  display: flex;
}

.scroll-container {
  bottom: 0;
  position: absolute;
  margin-bottom: 30px;
}

@media (max-width: 960px) {

  .back-button-port {
    position: absolute;
    text-decoration: none;
  }
}