.about {
  font-family: "Inter", sans-serif;
  overflow-y: auto;
}

.back-button-container-about {
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
}

.back-button-about {
  right: 0;
  top: 0;
  display: flex;
  position: fixed;
  transition: transform 0.15s ease-in-out;
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1.4rem;
  padding-top: 30px;
  padding-right: 35px;
  cursor: pointer;
}

.back-button-about:hover {
  transform: scale(1.05);
}

.back-text-about {
  padding-top: 10px;
  font-family: "inter", sans-serif;
  text-decoration: none;
}

.back-icon-about {
  padding-left: 15px;
  padding-top: 10px;
}

.about-tag {
  color: #afbfd6;
  line-height: "4";
  margin-bottom: 40px;
}

.about-content {
  flex: 1;
  width: 60%;
  margin-top: 100px;
  margin-left: 60px;
  margin-bottom: 200px;
}

.about-picture {
  height: 220px;
  width: 220px;
  padding: 10px;
  padding-right: 20px;
}

.about-pictures {
  right: 0;
  position: fixed;
  bottom: 0;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
}

.header-content {
  padding-top: 10px;
}

.header {
  display: flex;
  flex-direction: row;
}

.header-name {
  font-size: 35px;
  padding-top: 20px;
  padding-left: 40px;
  font-weight: 550;
}

.head {
  height: 130px;
  width: 130px;
  border-radius: 500px;
  left: 0;
}
.ring-container-about {
  position: relative;
  margin-left: 65px;
  margin-top: 5px;
}

.circle-about {
  width: 10px;
  height: 10px;
  background-color: #1bbe8d;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: -23px;
}

.location-about {
  color: #4b5767;
  font-family: "inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 65px;
  margin-bottom: 30px;
}

.ringring-about {
  border: 3px solid #1bbe8d;
  -webkit-border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -31px;
  top: -2px;
  -webkit-animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

h3 {
  font-size: 25px;
}

.block-items {
  display: flex;
  margin-left: -10px;
  flex-wrap: wrap;
}

.about-text {
  line-height: 1.6;
}

.a {
  color: black;
  font-weight: 700;
}

.about-email {
  padding-top: 50px;
  text-decoration: none;
  border-bottom: 1px dotted #212a36;
  color: #603bc5;
}

@media (min-width: 2401px) {
  .about-content {
    margin-left: 325px;
  }

  .about-pictures {
    margin-right: 100px;
  }
}

@media (min-width: 2200px) and (max-width: 2400px) {
  .about-content {
    margin-left: 250px;
  }

  .about-pictures {
    margin-right: 80px;
  }
}

@media (min-width: 1860px) and (max-width: 2199px) {
  .about-content {
    margin-left: 150px;
  }

  .about-pictures {
    margin-right: 60px;
  }
}

@media (max-width: 960px) {
  .about-pictures {
    flex-direction: row;
    position: relative;
    margin-right: 40px;
  }

  .about-picture {
    height: 200px;
    width: 200px;
    margin-right: 20px;
    padding: 0;
  }

  .about-content {
    margin-left: 40px;
    width: 88%;
  }

  .back-button-about {
    position: absolute;
    text-decoration: none;
  }
}

@media (max-width: 730px) {
  .about-content {
    margin-left: 30px;
  }

  .about-picture {
    height: 160px;
    width: auto;
  }
}

@media (max-width: 630px) {
  .about-picture {
    height: 130px;
    width: auto;
  }
}

@media (max-width: 520px) {
  .head {
    height: 100px;
    width: 100px;
    margin-top: 30px;
  }

  .header-name {
    padding-left: 20px;
  }

  .ring-container-about {
    margin-left: 45px;
  }

  .location-about {
    margin-left: 45px;
  }

  .head {
    margin-top: 20px;
  }

  .about-email {
    font-size: 25px;
  }
}

@media (max-width: 470px) {
  .about-pictures {
    flex-direction: column;
    margin-left: -5px;
  }
  .about-picture {
    height: 230px;
    width: 230px;
    padding: 5px;
  }
  .location-about {
    font-size: 12px;
  }
  .about-email {
    font-size: 18px;
  }
}

/* height */
@media (max-height: 830px) and (min-width: 900px) {
  .about-picture {
    height: 180px;
    width: 180px;
  }
}

@media (max-height: 700px) and (min-width: 900px) {
  .about-picture {
    height: 150px;
    width: 150px;
  }
}
