.navbar {
}

.navbar-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  list-style: none;
  overflow: hidden;
  top: 0;
  right: 0;
  padding-top: 20px;
}

.nav-item {
  margin-top: 5px;
  margin-right: 100px;
  transition: transform 0.15s ease-in-out;
}

.nav-item:hover {
  transform: scale(1.05);
}

.nav-links {
  color: black;
  text-decoration: none;
  font-family: "inter", sans-serif;
  color: white;
  font-size: 1.3rem;
}
